<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-service-warehouse-items' }">{{
            $t("MENU.ITEM.SERVICE.WAREHOUSE_ITEMS")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingServiceWarehouseItem"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <b-form>
          <ServiceWarehouseItemsForm
            ref="ServiceWarehouseItemsForm"
            :form="form"
          ></ServiceWarehouseItemsForm>

          <v-overlay :value="!firstLoader && isLoadingServiceWarehouseItem">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error[0] }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingServiceWarehouseItem"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_SERVICE_WAREHOUSE_ITEM } from "@/modules/service-warehouse-items/store/service-warehouse-items.module";

import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import KTCard from "@/view/content/Card.vue";
import ServiceWarehouseItemsForm from "../components/ServiceWarehouseItemsForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "ServiceWarehouseItemsAdd",
  components: {
    KTCard,
    SaveButtonDropdown,
    ServiceWarehouseItemsForm
  },
  data() {
    return {
      errors: {},
      form: {
        name: null,
        serviceWarehouseId: null
      }
    };
  },
  computed: {
    ...mapGetters(["isLoadingServiceWarehouseItem"])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.SERVICE.WAREHOUSE_ITEMS"),
        route: { name: "list-service-warehouse-items" }
      },
      { title: this.$i18n.t("LABELS.ADD_NEW") }
    ]);
  },
  methods: {
    onSave(next) {
      let vm = this;
      this.$refs.ServiceWarehouseItemsForm.$v.form.$touch();
      if (this.$refs.ServiceWarehouseItemsForm.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }
      this.$store
        .dispatch(SAVE_SERVICE_WAREHOUSE_ITEM, this.form)
        .then(data => {
          vm.errors = data.data.errors;
          let itemEdit = data.data;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-service-warehouse-items",
              params: { id: itemEdit.id }
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-service-warehouse-items" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-service-warehouse-items" });
          }
        })
        .catch(response => {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message
          });
        });
    }
  }
};
</script>
