<template>
  <v-card flat>
    <b-row>
      <b-col :md="6">
        <b-form-group
          id="name-group"
          v-bind:label="$t('FORM_LABELS.NAME')"
          label-for="name-input"
        >
          <FormInput
            id="name-input"
            v-model="form.name"
            type="text"
            name="name"
            :i18n="{
              label: 'FORM_LABELS.NAME',
              placeholder: $t('FORM_LABELS.NAME')
            }"
            :validateState="validateState('name')"
          ></FormInput>
        </b-form-group>
      </b-col>
      <b-col :md="6">
        <b-form-group
          id="item-serviceWarehouses-group"
          :label="$t('FORM_LABELS.WAREHOUSE')"
          label-for="item-serviceWarehouses-input"
        >
          <FormSelect
            clearable
            type="select"
            :options="serviceWarehouses"
            v-model="form.serviceWarehouseId"
            :validateState="validateState('serviceWarehouseId')"
          ></FormSelect>
        </b-form-group>
      </b-col>
    </b-row>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapState } from "vuex";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";
import { FETCH_SERVICE_WAREHOUSES } from "@/modules/service-warehouses/store/service-warehouses.module";

import FormInput from "@/view/content/forms/components/FormInput.vue";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";

export default {
  mixins: [validationMixin, formBuilderMixin],
  components: {
    FormInput,
    FormSelect
  },
  props: {
    form: Object
  },
  validations() {
    const tmpValidationObject = {
      form: {
        name: this.setItemValidations({
          required: true
        }),
        serviceWarehouseId: this.setItemValidations({
          required: true
        })
      }
    };

    return tmpValidationObject;
  },
  data() {
    return {
      errors: {},
      serviceWarehouses: []
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  mounted() {
    this.$store
      .dispatch(
        FETCH_SERVICE_WAREHOUSES,
        this.$url.transformParams({ onlySelectValues: true })
      )
      .then(data => {
        this.serviceWarehouses = data.data.items;
      });
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    }
  }
};
</script>
